import React from "react"
import PropTypes from "prop-types"
import Layout from '../components/Layout';
// Components
import { Link, graphql } from "gatsby"
import Block from "../components/Block"
import ListItem from "../components/ListItem"

const Tags = ({ pageContext, location, data }) => {
	const { tag } = pageContext
	const { edges, totalCount } = data.allMarkdownRemark
	const s = totalCount >= 2 ? 's'  : ''
	
	const tagHeader = `${totalCount} post${s} for #${tag}`
	return (
		<Layout location={location} title={data.allMarkdownRemark.siteTitle}>
			<Block size="small">
				<h2>{tagHeader}</h2>
				<ul style={{ listStyle: 'none' }}> 
					{edges.map(({ node }) => {
						const { slug } = node.fields
						const { title, date } = node.frontmatter
						return (
							<li key={slug}>
								<ListItem title={title} link={slug} date={date} ></ListItem>
							</li>
						)
					})}
				</ul>
				{/*
              This links to a page that does not yet exist.
              You'll come back to it!
            */}
				<Link to="/tags">All tags</Link>
			</Block>
		</Layout>
	)
}

Tags.propTypes = {
	pageContext: PropTypes.shape({
		tag: PropTypes.string.isRequired,
	}),
	data: PropTypes.shape({
		allMarkdownRemark: PropTypes.shape({
			totalCount: PropTypes.number.isRequired,
			edges: PropTypes.arrayOf(
				PropTypes.shape({
					node: PropTypes.shape({
						frontmatter: PropTypes.shape({
							title: PropTypes.string.isRequired,
						}),
						fields: PropTypes.shape({
							slug: PropTypes.string.isRequired,
						}),
					}),
				}).isRequired
			),
		}),
	}),
}
export default Tags
export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
			date(formatString: "MMM D")
          }
        }
      }
    }
  }
`
